import BubbleBackground from "../../components/bubble-background/BubbleBackground";
import CopyRight from "../../components/copyright/CopyRight";
import Modale from "../../components/modale/Modale";

const Home = () => {
  return (
    <BubbleBackground justifyContent="between">
      <div></div>
      <Modale>
        <div className="text-center">
          <div className="d-flex mb-3 align-items-center justify-content-center">
            <hr style={{flex: 1}} className="border-dark mx-3 d-none d-md-block" />
            <img src="/media/logo-red.png" alt="DataScientest Logo" style={{height: "1em"}} className="h1 mr-2" /> 
            <h1 className="m-0" style={{fontWeight: 300}}>DataScientest</h1>
            <hr style={{flex: 1}} className="border-dark mx-3 d-none d-md-block" />
          </div>
          <p>Welcome to QCM,<br />Without token, you won't be able to do anything.</p>
        </div>
      </Modale>
      <CopyRight />
    </BubbleBackground>
  );
}

export default Home;