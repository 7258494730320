import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Home from './pages/home/Home';
import Quiz from './pages/quiz/Quiz';
import NotFound from './pages/not-found/NotFound';
import RequireToken from './services/RequireToken';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/test">

          <Route index element={
            <Navigate to="/not-found" replace />
          }></Route>

          <Route path=":sessionId" element={
            <RequireToken>
              <Quiz />
            </RequireToken>
          }>
          </Route>

          <Route path="*" element={
            <Navigate to="/not-found" replace />
          }></Route>

        </Route>

        <Route path="/not-found" element={<NotFound />} />

        <Route path="*" element={
          <Navigate to="/not-found" replace />
        } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
