import axios from 'axios';

class Requester {

  getSessionInfo(sessionId, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_API_URL}customer/${sessionId}/${userId}`)
        .then(({ data }) => {
          if (process.env.REACT_APP_ENV === "dev") console.warn("getSessionInfo", data)
          resolve(data)
        })
        .catch(err => {
          console.error("Error in getExamInfo", err);
          if (err.response === undefined) {
            alert("Connection lost")
          } else {
            reject(err)
          }
        })
    })
  }

  getQuestion(sessionId, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_API_URL}customer/${sessionId}/${userId}/question`)
        .then(({ data }) => {
          if (process.env.REACT_APP_ENV === "dev") console.warn("getQuestion", data)
          resolve(data)
        })
        .catch(err => {
          console.error("Error in getQuestion", err)
          if (err.response === undefined) {
            alert("Connection lost")
          } else {
            reject(err)
          }
        })
    })
  }

  postQuestion(sessionId, userId, question) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_API_URL}customer/${sessionId}/${userId}/question`, {...question})
      .then(({ data }) => {
        if (process.env.REACT_APP_ENV === "dev") console.debug("postQuestion", data)
        resolve(data)
      })
      .catch(err => {
        console.error("Error in postQuestion", err)
        if (err.response === undefined) {
          alert("Connection lost")
        } else {
          reject(err)
        }
      })
    })
  }

  startExam(sessionId, userId) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_API_URL}customer/${sessionId}/${userId}/start`, {})
        .then(({ data }) => {
          if (process.env.REACT_APP_ENV === "dev") console.debug("startExam", data)
          resolve(data)
        })
        .catch(err => {
          console.error("Error in startExam", err)
          if (err.response === undefined) {
            alert("Connection lost")
          } else {
            reject(err.response)
          }
        })
    })
  }

}

export default new Requester();