import { Navigate, useSearchParams } from "react-router-dom";

export default function RequireToken({ children }) {
    const [params] = useSearchParams();
    const token = params.get("token");

    if (!token) {
      return <Navigate to={`/not-found`} replace />;
    }
  
    return children;
}
