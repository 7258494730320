import BubbleBackground from "../../components/bubble-background/BubbleBackground";
import CopyRight from "../../components/copyright/CopyRight";
import './NotFound.scss';

const NotFound = () => {
  return (
    <BubbleBackground>
      <div></div>
      <h1 id="not-found" className="text-white">Not Found</h1>
      <CopyRight />
    </BubbleBackground>
  );
};

export default NotFound;