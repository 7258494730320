import "./BubbleBackground.scss";

const BubbleBackground = ({justifyContent="center", children}) => {
  return (
    <section className={`d-flex flex-column justify-content-${justifyContent} align-items-center bubble-background`}>
      <div className="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {children}
    </section>
  );
}

export default BubbleBackground;