import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import Requester from '../../services/Requester';
import Modale from '../../components/modale/Modale';
import BubbleBackground from '../../components/bubble-background/BubbleBackground';
import CopyRight from "../../components/copyright/CopyRight";
import "./Quiz.scss";

const _startExam = async (startExamFunc, saveToSession, getQuestionFunc, setQuestion) => {
  try {
    await startExamFunc();
    const { question, answers, illustrations } = await getQuestionFunc();
    setQuestion({ resume: question, answers, illustrations, selection: [] });
    saveToSession();
  } catch (err) {
    console.error("Error in _startExam", err);
    alert("An error occurred");
  }
}

const _getQuestion = async (getQuestionFunc, setQuestion, incrementQuestionIndex, endLoading, endSession) => {
  const { question, answers, illustrations, ended, displayMultipleSolution } = await getQuestionFunc();
  if (ended && ended === true) {
    endSession();
    endLoading();
  } else {
    setQuestion({ resume: question, answers, illustrations, selection: [], displayMultipleSolution });
    if (incrementQuestionIndex) {
      incrementQuestionIndex();
    }
    endLoading();
  }
}

const _selectAnswer = (actualSelection, chosenAnswer) => {
  if (actualSelection.includes(chosenAnswer)) {
    actualSelection = actualSelection.filter(elem => elem !== chosenAnswer)
  } else {
    actualSelection.push(chosenAnswer);
  }
  return (actualSelection);
}

const _sendAnswer = async (startLoading, postQuestion, getQuestion) => {
  startLoading();
  try {
    await postQuestion();
    await getQuestion();
  } catch (err) {
    console.error(err);
    alert("An error occurred");
  }
}

const Quiz = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const token = urlParams.get('token');
  const [loading, setLoading] = useState(true);
  const [remainingTime, setRemainingTime] = useState(0);
  const [session, setSession] = useState({
    sessionTitle: 'Loading…',
    started: false,
    duration: null,
    questionLength: null,
    ended: false,
    endTime: null,
    questionIndex: null
  });
  const [question, setQuestion] = useState({});

  // Fetch info
  useEffect(() => {
    (async () => {
      let fetchedExam;
      try {
        fetchedExam = await Requester.getSessionInfo(params.sessionId, token)
        setSession(fetchedExam)
        if (fetchedExam.started && !fetchedExam.ended) {
          let intervalId;
          if (fetchedExam.endTime) {
            // Set Interval for remaining time
            intervalId = setInterval(() => {
              let remainingTimeValue = fetchedExam.endTime - ~~(Date.now() / 1000)
              setRemainingTime(remainingTimeValue);
              if (!remainingTimeValue) {
                // End session if time is out
                setSession({ ...session, ended: true })
              }
            }, 1000)
          }
          _getQuestion(
            () => Requester.getQuestion(params.sessionId, token),
            setQuestion,
            null,
            () => setLoading(false),
            () => setSession({ ...session, ended: true })
          );
          // Clear interval
          if (intervalId !== undefined) {
            return () => clearInterval(intervalId)
          }
        }
        else {
          setLoading(false)
        }
      }
      catch (err) {
        console.error("Err Quiz initialisation", err)
        if (err.response.status === 404) {
          alert("Examen not found. You'll now be redirected")
          navigate("/not-found", { replace: true })
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BubbleBackground justifyContent='between'>
      <div></div>
      {
        !loading ?
          <Modale>
            {
              !session.started ?
                (
                  <React.Fragment>
                    <div className="text-center">
                      <div className="d-flex mb-3 align-items-center justify-content-center">
                        <hr style={{ flex: 1 }} className="border-dark mx-3 d-none d-md-block" />
                        <img src="/media/logo-red.png" alt="DataScientest Logo" style={{ height: "1em" }} className="h1 mr-2" />
                        <h1 className="m-0" style={{ fontWeight: 300 }}>DataScientest</h1>
                        <hr style={{ flex: 1 }} className="border-dark mx-3 d-none d-md-block" />
                      </div>
                      <div className="text-center my-3 my-md-5">
                        <p className='h5'>{session.sessionTitle}</p>
                        <p className="h6 mb-0">{session.duration ? `This session lasts ${session.duration} minutes.` : ''} {session.questionLength ? `You will answer to ${session.questionLength} questions.` : ''}</p>
                      </div>
                    </div>
                    <button type="button" className="d-block btn btn-danger mx-auto mt-3 mt-md-5" onClick={() => _startExam(
                      () => Requester.startExam(params.sessionId, token),
                      () => setSession({ ...session, started: true }),
                      () => Requester.getQuestion(params.sessionId, token),
                      setQuestion
                    )}>Start</button>
                  </React.Fragment>
                )
                :
                (
                  !session.ended ?
                    <React.Fragment>
                      <h3 className='text-center'>{remainingTime ? (<React.Fragment>{~~(remainingTime / 60)}:{("00" + remainingTime % 60).slice(-2)}<br /></React.Fragment>) : ''}Question n°{(session.questionIndex || 0) + 1} :<br /><span dangerouslySetInnerHTML={{__html: question.resume}}></span></h3>
                      {
                        question.displayMultipleSolution ?
                          <p className='text-center info-text'><i>(Several possible solutions)</i></p>
                          :
                          <></>
                      }
                      <div>
                        {
                          question.illustrations.map((imageURL, index) => <img src={imageURL} alt={imageURL.toLowerCase()} className="illustration" key={index} />)
                        }
                      </div>
                      <div className='text-center my-3 my-md-5'>
                        {
                          question.answers.map((answer, index) => (
                            <button
                              className={`btn ${question.selection.includes(answer) ? 'selected' : ''} mx-3 my-2 px-5 selection`}
                              onClick={() => setQuestion({ ...question, selection: _selectAnswer(question.selection, answer) })}
                              key={index}
                            >{answer}</button>
                          ))
                        }
                      </div>
                      <div className='text-center'>
                        <button
                          type="button"
                          className={`btn btn-${question.selection.length ? 'success' : 'secondary'}`}
                          disabled={!question.selection.length}
                          onClick={() => _sendAnswer(
                            () => setLoading(true),
                            () => Requester.postQuestion(params.sessionId, token, question),
                            () => _getQuestion(
                              () => Requester.getQuestion(params.sessionId, token),
                              setQuestion,
                              () => setSession(session => ({ ...session, questionIndex: session.questionIndex + 1 })),
                              () => setLoading(false),
                              () => setSession({ ...session, ended: true })
                            )
                          )}
                        >Confirm</button>
                      </div>
                    </React.Fragment>
                    :
                    <div className='text-center'>
                      <h3>Voilà 🎉</h3>
                      <p>You will be contacted back about your result.</p>
                    </div>
                )
            }
          </Modale>
          :
          <div className="spinner-border text-white" role="status">
            <span className="sr-only">Loading...</span>
          </div>
      }
      <CopyRight />
    </BubbleBackground>
  )
};

export default Quiz;